import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Categorias = find("[js-page='super-juri-single']")
	if (!Categorias) return
	console.log('Page superjuri individual');

	const categoriasTop = document.querySelectorAll('[data-cat-jurado-individual-top]')
	const categoriasBottom = document.querySelectorAll('[data-cat-jurado-individual-bottom]')

	const showCategoriaHover = () => {
		categoriasTop?.forEach(cat => {
			cat.addEventListener('mouseenter', () => {
				categoriasBottom.forEach(catB => {
					if (cat.dataset.catJuradoIndividualTop === catB.dataset.catJuradoIndividualBottom) {
						categoriasBottom.forEach(catB => {
							catB.classList.add('hidden')
						})
						catB.classList.remove('hidden')
					}
				})
			})
		})
	}

	const hiddenCategoriaHover = () => {
		categoriasTop?.forEach(cat => {
			cat.addEventListener('mouseleave', () => {
				categoriasBottom.forEach(catB => {
					if (cat.dataset.catJuradoIndividualTop === catB.dataset.catJuradoIndividualBottom) {
						categoriasBottom.forEach(catB => {
							catB.classList.add('hidden')
						})
					}
				})
			})
		})
	}

	function richTextParseSuperjuriSingle() {
		function parseJSONToHTML(json) {
			const data = JSON.parse(json);

			let html = "";

			for (const block of data.blocks) {
				switch (block.type) {
					case "paragraph":
						html += `<p class="paragraph">${block.data.text}</p>`;
						break;
					case "Header":
						html += `<h${block.data.level} class="header">${block.data.text}</h${block.data.level}>`;
						break;
					case "Table":
						html += `<table class="table">${block.data.content}</table>`;
						break;
					case "Raw":
						html += `<div class="raw">${block.data.html}</div>`;
						break;
					case "Delimiter":
						html += '<hr class="delimiter">';
						break;
					case "Code":
						html += `<pre class="code"><code>${block.data.code}</code></pre>`;
						break;
					case "Quote":
						html += `<blockquote class="quote">${block.data.text}</blockquote>`;
						break;
					case "List":
						const listItems = block.data.items
							.map((item) => `<li>${item}</li>`)
							.join("");
						const listStyle = block.data.style === "ordered" ? "ol" : "ul";
						html += `<${listStyle} class="list">${listItems}</${listStyle}>`;
						break;
					case "Embed":
						html += `<div class="embed ${block.data.service}">
									<iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
									</div>`;
						break;
					case "Checklist":
						const checklistItems = block.data.items
							.map((item) => {
								const checked = item.checked ? "checked" : "";
								return `<li>
										<input  type="checkbox" onclick="return false;"  ${checked}>
										<label> ${item.text} </label>
										</li>`;
							})
							.join("");
						html += `<ul>${checklistItems}</ul>`;
						break;
					case "Image":
						html += `<img src="${block.data.file.url}" alt="${block.data.caption}" class="image">`;
						break;
					case "Warning":
						html += `<div class="warning">
								<h3>${block.data.title}</h3>
								<p>${block.data.message}</p>
								</div>`;
						break;
					default:
						// Handle unknown block types here
						break;
				}
			}

			return html;
		}

		const blogContent = document.querySelectorAll(".text-content-biografia");
		blogContent.forEach((blog) => {
			if (blog.dataset.conteudoDescricaoBiografia) {
				if (
					JSON.parse(blog.dataset.conteudoDescricaoBiografia).blocks.length > 0
				) {
					const htmlParsed = parseJSONToHTML(
						blog.dataset.conteudoDescricaoBiografia
					);
					blog.innerHTML = htmlParsed;
				} else {
					blog.closest("div").style.display = "none";
				}
			} else {
				blog.closest("div").style.display = "none";
			}
		});
	}

	const updateTitlePage = () => {
		const tituloAtual = document.title;
		const nomeJurado = document.querySelector('[data-nome-jurado]')
		document.title = `${tituloAtual} ${nomeJurado.dataset.nomeJurado}`
	}

	showCategoriaHover()
	hiddenCategoriaHover()
	richTextParseSuperjuriSingle();
	updateTitlePage()
}