import Swiper from "swiper"
import { find } from "utils/dom"
import logs from "../utils/logs"

export default function () {
	const Categoria = find("[js-page='votacao']")
	if (!Categoria) return

	const generateSWiper = () => {
		const listSwiper = []
		const swipers = document.querySelectorAll('.swiper-container')

		swipers.forEach((el, i) => {
			const prev = el.querySelector('.item-prev')
			const next = el.querySelector('.item-next')

			el.classList.add(`swiperAtletasVotacao${i}`)
			prev.classList.add(`swiper-atleta-votacao-prev-${i}`)
			next.classList.add(`swiper-atleta-votacao-next-${i}`)
		})

		swipers.forEach((swiper, indice) => {
			const swiperAtletasVotacao = new Swiper(`.swiperAtletasVotacao${indice}`, {
				slidesPerView: 'auto',
				spaceBetween: 30,
				disableOnInteraction: true,
				reverseDirection: false,

				navigation: {
					nextEl: `.swiper-atleta-votacao-next-${indice}`,
					prevEl: `.swiper-atleta-votacao-prev-${indice}`,
				}
			});

			listSwiper.push(swiperAtletasVotacao)
		})
	}

	generateSWiper()

	function richTextParseCategoria() {
		function parseJSONToHTML(json) {
			const data = JSON.parse(json);

			let html = "";

			for (const block of data.blocks) {
				switch (block.type) {
					case "paragraph":
						html += `<p class="paragraph">${block.data.text}</p>`;
						break;
					case "Header":
						html += `<h${block.data.level} class="header">${block.data.text}</h${block.data.level}>`;
						break;
					case "Table":
						html += `<table class="table">${block.data.content}</table>`;
						break;
					case "Raw":
						html += `<div class="raw">${block.data.html}</div>`;
						break;
					case "Delimiter":
						html += '<hr class="delimiter">';
						break;
					case "Code":
						html += `<pre class="code"><code>${block.data.code}</code></pre>`;
						break;
					case "Quote":
						html += `<blockquote class="quote">${block.data.text}</blockquote>`;
						break;
					case "List":
						const listItems = block.data.items
							.map((item) => `<li>${item}</li>`)
							.join("");
						const listStyle = block.data.style === "ordered" ? "ol" : "ul";
						html += `<${listStyle} class="list">${listItems}</${listStyle}>`;
						break;
					case "Embed":
						html += `<div class="embed ${block.data.service}">
									<iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
									</div>`;
						break;
					case "Checklist":
						const checklistItems = block.data.items
							.map((item) => {
								const checked = item.checked ? "checked" : "";
								return `<li>
										<input  type="checkbox" onclick="return false;"  ${checked}>
										<label> ${item.text} </label>
										</li>`;
							})
							.join("");
						html += `<ul>${checklistItems}</ul>`;
						break;
					case "Image":
						html += `<img src="${block.data.file.url}" alt="${block.data.caption}" class="image">`;
						break;
					case "Warning":
						html += `<div class="warning">
								<h3>${block.data.title}</h3>
								<p>${block.data.message}</p>
								</div>`;
						break;
					default:
						// Handle unknown block types here
						break;
				}
			}

			return html;
		}

		const blogContent = document.querySelectorAll(".text-content-biografia-categoria");
		blogContent.forEach((blog) => {
			if (blog.dataset.conteudoDescricaoBiografia) {
				if (
					JSON.parse(blog.dataset.conteudoDescricaoBiografia).blocks.length > 0
				) {
					const htmlParsed = parseJSONToHTML(
						blog.dataset.conteudoDescricaoBiografia
					);
					blog.innerHTML = htmlParsed;
				} else {
					blog.closest("div").style.display = "none";
				}
			} else {
				blog.closest("div").style.display = "none";
			}
		});
	}

	richTextParseCategoria()

	document.addEventListener('DOMContentLoaded', () => {
		const buttons = document.querySelectorAll('.frame button');
		const cards = document.querySelectorAll('.grid-cards > div');
		const todas = document.querySelectorAll('.grid-cards .todas');
		cards.forEach(card => card.classList.add('ativo'));
		cards.forEach(card => card.classList.add('hidden'));


		buttons.forEach(button => {
			button.addEventListener('click', () => {

				buttons.forEach(btn => btn.classList.remove('ativo'));

				button.classList.add('ativo');

				const filter = button.parentElement.id;

				cards.forEach(card => {
					card.classList.remove('ativo');
					setTimeout(() => {
						card.classList.add('hidden');
					}, 200);
				});

				cards.forEach(card => {
					if (card.classList.contains(filter)) {
						card.classList.remove('hidden');
						setTimeout(() => {
							card.classList.add('ativo');
						}, 0);
					}
				});
			});
		});
	});

	const filterCategoria = () => {
		const categoriasHeader = document.querySelectorAll('[data-categoria-votacao-header]')
		const categoriasGrid = document.querySelectorAll('[data-categoria-votacao-bottom]')

		categoriasHeader.forEach(header => {
			header.addEventListener('click', (e) => {
				categoriasGrid.forEach(cat => {
					if (header.dataset.categoriaVotacaoHeader === cat.dataset.categoriaVotacaoBottom) {
						cat.classList.add('block')
						cat.classList.remove('hidden')
					}
					else if (header.dataset.categoriaVotacaoHeader === 'todas') {
						cat.classList.remove('hidden')
					}
					else {
						cat.classList.add('hidden')
					}
				})
			})
		})
	}

	filterCategoria()

	const numbersHeader = () => {
		const allCategorias = document.querySelectorAll('.todas')
		const qtdCategorias = document.querySelector('[data-qtd-itens-categoria]')
		const qtdCategoriaTotal = document.querySelector('[data-total-categoria]')

		const categoriasHeader = document.querySelectorAll('[data-categoria-votacao-header]')
		const categoriasBottom = document.querySelectorAll('[data-categoria-votacao-bottom]')

		qtdCategorias.innerText = allCategorias.length
		qtdCategoriaTotal.innerText = allCategorias.length

		categoriasHeader?.forEach(header => {
			header?.addEventListener('click', (e) => {
				const arr = Array.from(categoriasBottom)
				const res = arr?.filter(cat => cat.classList.contains(`${header.dataset.categoriaVotacaoHeader}`))
				qtdCategorias.innerText = res.length
			})
		})
	}

	numbersHeader()

}